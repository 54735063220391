import React, { Component } from "react";
import { Layout, Pagination, SubpageHeader } from "../components";
import { Link } from "gatsby";

class Blog extends Component {
  state = {};
  render() {
    const {
      group,
      categories,
      pageCount,
      pathPrefix,
      activeCategory,
      first,
      last,
      index,
      seo,
    } = this.props.pageContext;
    const { href } = this.props.location;
    const pagination = { pageCount, first, last, index, pathPrefix };
    const next = index + 1 <= pageCount ? `/page/${index + 1}` : null;
    const prev =
      index - 1 > 0 ? (index === 2 ? "/" : `/page/${index - 1}`) : null;
    return (
      <Layout
        seo={{
          ...seo,
          title: index === 1 ? "Blog" : `Blog - Strona ${index} z ${pageCount}`,
          description:
            "Odkryj skuteczne strategie marketingowe i trendy w branży dzięki naszemu blogowi o marketingu. Znajdziesz tu praktyczne porady i ciekawe artykuły, które pomogą Ci w budowaniu silnej marki i zwiększeniu sprzedaży. Odwiedź naszą stronę i poszerz swoją wiedzę na temat marketingu.",
          href: href && href.replace(`/page/${index}`, ""),
          next,
          prev,
          lang: "pl",
        }}
      >
        <SubpageHeader title="blog" />
        {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
        <div className="main-wrapper">
          <section className="container-fluid posts-container">
            <div className="row">
              <aside className="col-md-3 panel-category-nav">
                <ul className="categories">
                  <li
                    className={`cat-item ${
                      activeCategory === "all" ? "active" : " "
                    }`}
                    onMouseEnter={({ target }) =>
                      activeCategory !== "all" && target.classList.add("active")
                    }
                    onMouseLeave={({ target }) =>
                      activeCategory !== "all" &&
                      target.classList.remove("active")
                    }
                  >
                    <Link to="/blog/">Wszystko</Link>
                  </li>
                  {categories.map(({ node }) => (
                    <>
                      {node.count > 0 && (
                        <li
                          className={`cat-item ${
                            activeCategory === node.name ? "active" : " "
                          }`}
                          key={node.id}
                          onMouseEnter={({ target }) =>
                            activeCategory !== node.name &&
                            target.classList.add("active")
                          }
                          onMouseLeave={({ target }) =>
                            activeCategory !== node.name &&
                            target.classList.remove("active")
                          }
                        >
                          <Link to={`/${node.slug}#list`}>{node.name}</Link>
                        </li>
                      )}
                    </>
                  ))}
                </ul>
              </aside>

              <svg className="hover-filter" xmlns="http://www.w3.org/2000/svg">
                <filter
                  id="hover-filter"
                  x="-10%"
                  y="-10%"
                  width="120%"
                  height="120%"
                  filterUnits="objectBoundingBox"
                  primitiveUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0
                          1 0 0 0 0
                          1 0 0 0 0
                          0 0 0 1 0"
                    in="SourceGraphic"
                    result="colormatrix"
                  />
                  <feComponentTransfer
                    in="colormatrix"
                    result="componentTransfer"
                  >
                    <feFuncR type="table" tableValues="0.45 0.27" />
                    <feFuncG type="table" tableValues="0.1 0.9" />
                    <feFuncB type="table" tableValues="0.68 0.85" />
                    <feFuncA type="table" tableValues="0 1" />
                  </feComponentTransfer>
                  <feBlend
                    mode="normal"
                    in="componentTransfer"
                    in2="SourceGraphic"
                    result="blend"
                  />
                </filter>
              </svg>

              <div className="col-md-9 panel-posts">
                <span className="anchor_blog" id="list"></span>
                <div className="row">
                  {group.map(({ node }, i) => (
                    <div className="col-md-6 post-col" key={i}>
                      <article key={node.id} className="post-item">
                        <Link to={node.path} className="link_wrapper">
                          <div className="post-item__thumbnail-wrapper thumbnail-wrapper">
                            <img
                              className="img-fluid post-item__image image"
                              src={node?.acf?.featured_img?.source_url}
                              alt=""
                            />
                            <div className="category">
                              <p className="category__name">
                                {node.categories[0].name}
                              </p>
                            </div>
                          </div>
                          <div className="post-item__info info">
                            <p className="info__date">
                              {node.date
                                .split("T")[0]
                                .split("-")
                                .reverse()
                                .join(".")}
                            </p>
                            <p className="info__author">{node.author.name}</p>
                          </div>
                          <h2
                            className="post-item__title"
                            dangerouslySetInnerHTML={{
                              __html: node.title,
                            }}
                          />
                          <div
                            className="post-item__desc"
                            dangerouslySetInnerHTML={{
                              __html: node.acf.desc,
                            }}
                          ></div>
                        </Link>
                      </article>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Pagination {...pagination} />
          </section>
        </div>
      </Layout>
    );
  }
}

export default Blog;
